import React, { useState, useCallback, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { Toaster } from 'react-hot-toast';
import Logo from './components/Logo';
import Navigation from './components/Navigation';
import { ThemeProvider } from './contexts/ThemeContext';
import { useIntersectionObserver } from './hooks/useIntersectionObserver';
import { usePreloadComponents } from './hooks/usePreloadComponents';
import Modal from './components/Modal';
import LoadingFallback from './components/LoadingFallback';
import { lazyWithRetry } from './lib/lazyLoad';

// Lazy load components with retry and delay
const Intro = lazyWithRetry(() => import('./components/sections/Intro'));
const Work = lazyWithRetry(() => import('./components/sections/Work'));
const Values = lazyWithRetry(() => import('./components/sections/Values'));
const Background = lazyWithRetry(() => import('./components/sections/Background'));
const References = lazyWithRetry(() => import('./components/sections/References'));
const Contact = lazyWithRetry(() => import('./components/sections/Contact'));
const CaseStudy = lazyWithRetry(() => import('./components/CaseStudy').then(mod => ({ default: mod.CaseStudy })));
const CaseStudyEditor = lazyWithRetry(() => import('./components/admin/CaseStudyEditor'));
const AdminLogin = lazyWithRetry(() => import('./components/admin/AdminLogin'));
const NewProductStartup = lazyWithRetry(() => import('./components/case-studies/NewProductStartup'));

function MainLayout() {
  const [activeTab, setActiveTab] = useState('intro');
  const [activeCaseStudy, setActiveCaseStudy] = useState<string | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Enable component preloading
  usePreloadComponents();

  const handleIntersection = useCallback((id: string) => {
    requestAnimationFrame(() => {
      setActiveTab(id);
    });
  }, []);

  useIntersectionObserver(handleIntersection);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      requestAnimationFrame(() => {
        setActiveTab(id);
      });
    }
    setIsMobileMenuOpen(false);
  };

  const handleCaseStudyClick = (id: string) => {
    setActiveCaseStudy(id);
  };

  const handleCaseStudyClose = () => {
    setActiveCaseStudy(null);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const nav = document.getElementById('mobile-nav');
      const button = document.getElementById('mobile-menu-button');
      if (nav && !nav.contains(event.target as Node) && 
          button && !button.contains(event.target as Node)) {
        setIsMobileMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="min-h-screen bg-white">
      {/* Mobile Header */}
      <header className="lg:hidden fixed top-0 left-0 right-0 z-50 bg-white border-b px-4 py-3">
        <div className="flex items-center justify-between">
          <Logo />
          <button 
            id="mobile-menu-button"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="p-2 hover:bg-gray-100 rounded-lg"
            aria-label="Toggle menu"
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </header>

      {/* Navigation Container */}
      <aside 
        id="mobile-nav"
        className={`
          fixed inset-0 bg-white z-40
          lg:fixed lg:left-0 lg:top-0 lg:bottom-0 lg:w-64
          transform transition-transform duration-300 ease-in-out
          ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'}
        `}
      >
        <div className="h-full overflow-y-auto">
          <div className="flex flex-col px-8 pt-20 lg:pt-8">
            <div className="hidden lg:block mb-16">
              <Logo />
            </div>
            <div className="flex-1">
              <Navigation 
                activeTab={activeTab} 
                onTabChange={scrollToSection}
              />
            </div>
          </div>
        </div>
      </aside>

      <main className="lg:pl-64">
        <div className="px-4 lg:px-8 mt-16 lg:mt-0">
          <div className="space-y-[30vh]">
            <section id="intro" className="min-h-[calc(100vh-64px)] flex items-start">
              <Suspense fallback={<LoadingFallback minHeight="calc(100vh-64px)" />}>
                <Intro />
              </Suspense>
            </section>
            <section id="work" className="min-h-[calc(100vh-64px)] flex items-center">
              <Suspense fallback={<LoadingFallback minHeight="calc(100vh-64px)" />}>
                <Work onCaseStudyClick={handleCaseStudyClick} />
              </Suspense>
            </section>
            <section id="values" className="min-h-[calc(100vh-64px)] flex items-center">
              <Suspense fallback={<LoadingFallback minHeight="calc(100vh-64px)" />}>
                <Values />
              </Suspense>
            </section>
            <section id="background" className="min-h-[calc(100vh-64px)] flex items-center">
              <Suspense fallback={<LoadingFallback minHeight="calc(100vh-64px)" />}>
                <Background onCaseStudyClick={handleCaseStudyClick} />
              </Suspense>
            </section>
            <section id="references" className="min-h-[calc(100vh-64px)] flex items-center">
              <Suspense fallback={<LoadingFallback minHeight="calc(100vh-64px)" />}>
                <References />
              </Suspense>
            </section>
            <section id="contact" className="min-h-[calc(100vh-64px)] flex items-center">
              <Suspense fallback={<LoadingFallback minHeight="calc(100vh-64px)" />}>
                <Contact />
              </Suspense>
            </section>
          </div>
        </div>

        <Modal isOpen={activeCaseStudy !== null} onClose={handleCaseStudyClose}>
          <Suspense fallback={<LoadingFallback />}>
            {activeCaseStudy && (
              <CaseStudy 
                id={activeCaseStudy} 
                onClose={handleCaseStudyClose}
              />
            )}
          </Suspense>
        </Modal>
      </main>
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <ThemeProvider>
        <Routes>
          <Route path="/admin" element={
            <Suspense fallback={<LoadingFallback />}>
              <AdminLogin />
            </Suspense>
          } />
          <Route path="/admin/dashboard" element={
            <Suspense fallback={<LoadingFallback />}>
              <CaseStudyEditor />
            </Suspense>
          } />
          <Route path="/case-studies/new-product-startup" element={
            <Suspense fallback={<LoadingFallback />}>
              <NewProductStartup />
            </Suspense>
          } />
          <Route path="/*" element={<MainLayout />} />
        </Routes>
        <Toaster position="bottom-right" />
      </ThemeProvider>
    </Router>
  );
}