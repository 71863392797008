import { lazy } from 'react';

// Helper to add loading delay for smoother UX
export function lazyWithRetry(factory: () => Promise<any>) {
  return lazy(() => {
    return new Promise((resolve) => {
      // Add small delay to prevent flash of loading state
      const timeoutId = setTimeout(() => {
        resolve(factory());
      }, 100);

      return () => clearTimeout(timeoutId);
    });
  });
}

// Helper to preload components
export function preloadComponent(factory: () => Promise<any>) {
  const component = factory();
  return component; // Return promise for chaining
}