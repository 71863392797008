import React from 'react';

interface LoadingFallbackProps {
  minHeight?: string;
}

export default function LoadingFallback({ minHeight = '200px' }: LoadingFallbackProps) {
  return (
    <div 
      className="flex items-center justify-center" 
      style={{ minHeight }}
    >
      <div className="animate-pulse text-gray-400">Loading...</div>
    </div>
  );
}