import { useEffect } from 'react';
import { preloadComponent } from '../lib/lazyLoad';

// Preload components based on viewport visibility
export function usePreloadComponents() {
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const sectionId = entry.target.id;
            
            // Preload next section when current section is visible
            switch(sectionId) {
              case 'intro':
                preloadComponent(() => import('../components/sections/Work'));
                break;
              case 'work':
                preloadComponent(() => import('../components/sections/Values'));
                break;
              case 'values':
                preloadComponent(() => import('../components/sections/Background'));
                break;
              case 'background':
                preloadComponent(() => import('../components/sections/References'));
                break;
              case 'references':
                preloadComponent(() => import('../components/sections/Contact'));
                break;
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    // Observe all sections
    document.querySelectorAll('section[id]').forEach(
      section => observer.observe(section)
    );

    return () => observer.disconnect();
  }, []);
}