import React from 'react';
import { useLocation } from 'react-router-dom';

interface NavigationProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const tabs = [
  { id: 'intro', label: 'Intro' },
  { id: 'work', label: 'Work' },
  { id: 'values', label: 'Values' },
  { id: 'background', label: 'Background' },
  { id: 'references', label: 'References' },
  { id: 'contact', label: 'Contact' }
];

export default function Navigation({ activeTab, onTabChange }: NavigationProps) {
  const location = useLocation();

  return (
    <nav className="w-full mt-8 lg:mt-0">
      <ul className="flex flex-col space-y-4">
        {tabs.map(tab => (
          <li key={tab.id}>
            <button
              onClick={() => onTabChange(tab.id)}
              className={`
                text-[15px] leading-none w-full text-left pl-4 py-1
                ${activeTab === tab.id 
                  ? 'text-gray-900 relative before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-2 before:h-2 before:bg-blue-500 before:rounded-full'
                  : 'text-gray-400 hover:text-gray-900 transition-colors'}
              `}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
}