import React, { useEffect, useRef, useState } from 'react';
import { X } from 'lucide-react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export default function Modal({ isOpen, onClose, children }: ModalProps) {
  const [dragStart, setDragStart] = useState<number | null>(null);
  const [dragOffset, setDragOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const dragThreshold = 100;

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  useEffect(() => {
    if (!isOpen) {
      setDragOffset(0);
      setDragStart(null);
      setIsDragging(false);
    }
  }, [isOpen]);

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      e.preventDefault();
      e.stopPropagation();
      onClose();
    }
  };

  // Touch handlers
  const handleTouchStart = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    setDragStart(touch.clientY);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (dragStart === null) return;
    const touch = e.touches[0];
    const diff = touch.clientY - dragStart;
    if (diff > 0) {
      setDragOffset(diff);
    }
  };

  const handleTouchEnd = () => {
    if (dragOffset > dragThreshold) {
      onClose();
    }
    setDragOffset(0);
    setDragStart(null);
  };

  // Mouse handlers
  const handleMouseDown = (e: React.MouseEvent) => {
    setDragStart(e.clientY);
    setIsDragging(true);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (dragStart === null || !isDragging) return;
    const diff = e.clientY - dragStart;
    if (diff > 0) {
      setDragOffset(diff);
    }
  };

  const handleMouseUp = () => {
    if (dragOffset > dragThreshold) {
      onClose();
    }
    setDragOffset(0);
    setDragStart(null);
    setIsDragging(false);
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50 flex items-end lg:items-center justify-center"
      onClick={handleBackdropClick}
      aria-modal="true"
      role="dialog"
    >
      {/* Backdrop */}
      <div 
        className="fixed inset-0 bg-black/30 backdrop-blur-sm"
        onClick={handleBackdropClick}
      />
      
      {/* Content Container */}
      <div 
        ref={modalRef}
        className={`
          relative w-full bg-white overflow-hidden
          lg:w-[calc(100vw-64px)] lg:max-w-4xl lg:rounded-[23.33px] lg:shadow-xl
          transform transition-transform duration-300 ease-out
          ${isDragging ? 'cursor-grabbing' : ''}
        `}
        onClick={(e) => e.stopPropagation()}
        style={{
          maxHeight: 'calc(100vh - env(safe-area-inset-bottom, 0px))',
          transform: dragOffset ? `translateY(${dragOffset}px)` : undefined
        }}
      >
        {/* Header with drag handle and close button */}
        <div 
          className="sticky top-0 z-10 bg-white"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
        >
          {/* Drag Handle (mobile only) */}
          <div className="lg:hidden w-full h-8 flex items-center justify-center cursor-grab active:cursor-grabbing">
            <div className="w-12 h-1 bg-gray-300 rounded-full" />
          </div>

          {/* Close Button */}
          <button 
            onClick={onClose}
            className="absolute right-4 top-4 p-2 hover:bg-gray-100 rounded-full transition-colors"
            aria-label="Close modal"
          >
            <X size={24} />
          </button>
        </div>

        {/* Scrollable Content */}
        <div 
          className="relative overflow-y-auto overscroll-contain"
          style={{
            maxHeight: 'calc(90vh - env(safe-area-inset-bottom, 0px) - 48px)',
            minHeight: '50vh'
          }}
        >
          <div className="p-6 pt-2 md:p-8 md:pt-4">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}